import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DashBoard() {
  const navigate = useNavigate();
  // let location = "https://dev.efarm.digitalgreen.org/FPOManagement";
  let env = window.location.href?.split("//")[1]?.split(".")?.[0];

  const dashboardUrl = env
    ? `https://${env}.efarm.digitalgreen.org/dashboard`
    : "https://efarm.digitalgreen.org/dashboard";

  const fpc_name = localStorage.getItem("first_name");
  const user_id = JSON.parse(localStorage.getItem("userId"));
  console.log("fpc_name", fpc_name);
  console.log("user_id", user_id);
  console.log(
    "🚀 ~ DashBoard ~ `${dashboardUrl}?user_id=${user_id}`:",
    `${dashboardUrl}?user_id=${user_id}`
  );

  useEffect(() => {
    if (!user_id) {
      navigate("/login");
    }
  }, [user_id, navigate]);

  if (!user_id) {
    return null; // Optionally render nothing while redirecting
  }
  return (
    <>
      <div>
        <iframe
          title="efarm-dashboard"
          src={`${dashboardUrl}?user_id=${user_id}`}
          width={1400}
          height={900}
          id="reports"
        ></iframe>
      </div>
    </>
  );
}

export default DashBoard;
